import React,{Component} from 'react'
import './report.css';
import $ from 'jquery'
import * as Pbi from 'powerbi-client'
import msrest from 'ms-rest'

class Report extends Component{
  constructor(props){
    super(props)
  }
  render(){
    return(
      <div className='Report' id="embedContainer">
        <div className="mask"></div>
        <iframe width="100%" src="https://app.powerbi.com/view?r=eyJrIjoiNmQ5NTA4MzktZGIzNC00NTUxLWI4NWUtMzBkYjQzZDA3ZmY2IiwidCI6IjI1YTc3MjA0LTYyMmMtNDc5Ny1iZmQ3LTJmNmY2NDkxNmVhZiJ9" frameborder="0" allowFullScreen="true" ></iframe>
      </div>
    )
  }

  componentDidMount(){
    var screenheight = document.body.clientHeight - 70
    var iframe = $('iframe')
    iframe.attr('height',screenheight)

    document.body.addEventListener('resize',()=>{
      var screenheight = document.body.clientHeight - 70
      iframe.attr('height',screenheight)
      console.log(screenheight)
    })

   
  }

}

export default Report;