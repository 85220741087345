import React,{Component} from 'react'
import './AcceuilHome.css';
import Firebase from 'firebase'
import {withRouter} from 'react-router-dom'
import picture1 from './hadirate1.jpg'
import picture2 from './hadirate2.jpg'
import next from './right-arrow.svg'

import {Container,Row,Col,Button,InputGroup,FormControl,Form} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery';
class AcceuilHome extends Component{
  constructor(props){
    super(props)
    this.state = {
      zoneinfo:[],
      syntheseInit:{},
      syntheseFinal:{}
    }
  }
  

   componentWillMount(){
     
   }

   componentDidMount(){
     $('ul').hide()
    fetch('https://integralhub.net:3002/getSyntheseZone').then((data)=>{
      
      let rows = []
      data.json().then((dataJson)=>{
         dataJson.recordset.forEach(item => {
           rows.push(item)
         })
      let PLinit = 0
      let Pinit = 0
      let ConsoAncien = 0

      let PLrempl = 0
      let Extentions = 0
      let HFL = 0
      let Pfinal = 0
      let ConsoNouveau = 0
      rows.forEach((val)=>{
        PLinit = PLinit + val.POINT_ORIGIN
        Pinit = Pinit + val.Puiss_ancien
        ConsoAncien = ConsoAncien + val.CONSO_AVANT_RENOUV
      
        PLrempl = PLrempl + val.POINT_REMPL
        Extentions = Extentions + val.EXTENTION
        HFL = HFL + val.HFL
        Pfinal = Pfinal + val.Puiss_nouveau
        ConsoNouveau = ConsoNouveau + val.CONSO_ACTU
      })


         this.setState({
            zoneinfo:rows,
            syntheseInit:{
              PLinit:PLinit,
              Pancien : Pinit/1000,
              ConsoAncien:ConsoAncien
            },
            syntheseFinal:{
              PLrempl:PLrempl,
              Extentions:Extentions,
              HFL:HFL,
              Pfinal:(Pfinal+Pinit)/1000,
              ConsoNouveau:ConsoNouveau
            }
          })
         
        })

        $('ul').show()
      })
   }

   render(){
     console.log(this.state.zoneinfo[0])
    return(
      
      <div className='AcceuilHome'>
        <a href="/EP_Marrakech/Dashboard/Filter"><img className="homenext" src={next} width="100" height="50" title="Filtre par zone"/></a>
       <div className="homeInit">
         <h3>Etat initial</h3>
         <img src={picture1} width="120" height="120"/>
         <div className="homeData">
           <ul>
             <li>{this.state.syntheseInit.PLinit} Points lumineux</li>
             <li>Puissance installée: {/*parseFloat(this.state.syntheseInit.Pancien).toFixed(2)*/}10 Mw</li>
             <li>Consommation énergetique: {parseFloat(this.state.syntheseInit.ConsoAncien).toFixed(2)} GWh</li>
           </ul>
         </div>
       </div>
       <div className="homeFinal">
       <h3>Etat actuel</h3>
       <img src={picture2} width="120" height="120"/>
       <div className="homeData">
       <ul>
             <li>{this.state.syntheseFinal.PLrempl} Points lumineux <span style={{"font-weight":"bolder"}}>remplacés</span></li>
             <li>Extention H.A.A: {this.state.syntheseFinal.Extentions}</li>
             <li>HFL: {this.state.syntheseFinal.HFL}</li>
             <li>Puissance installée: {parseFloat(this.state.syntheseFinal.Pfinal).toFixed(2)} MW</li>
             <li>Consommation énergetique: {parseFloat(this.state.syntheseFinal.ConsoNouveau).toFixed(2)} GWh</li>
           </ul>
       </div>
       </div>
      </div>
      
    )
  }
}

export default withRouter(AcceuilHome);