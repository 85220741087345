import React,{Component} from 'react'
import './footer.css';

class Footer extends Component{
  constructor(props){
    super(props)
  }

  render(){
    return(
      <div className='footer'>
       <span>© Copyright 2020 PROD'AIR</span>
      </div>
    )
  }
}

export default Footer;