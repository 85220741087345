import React,{Component} from 'react'
import './coffret.css';
import $ from 'jquery';
import Forms from '../OfficeForm/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Form,Button,Modal,Spinner,Card} from 'react-bootstrap'
import { Container } from 'reactstrap';
import { MDBDataTableV5 } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'mdbreact/dist/css/mdb.css'
import logoutIcon from './logout.svg'
import logo1 from "./Logo-prod'air.png"
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import download from './download.svg'


class Coffret extends Component{
  constructor(props){
    super(props)
    this.state = {
      data:{
        columns:[],
        rows:[]
      },
      value:[],
      row:[],
      columns:[
        {
          label: 'N° Coffret',
          field: 'ncoffret',
          sort: 'asc',
          width: 100,
          sort: 'disabled'
        },
        {
          label: 'Nom Coffret',
          field: 'nomcoffret',
          width: 150,
          sort: 'disabled'
        },
        {
          label: 'N° Contrat',
          field: 'ncontrat',
          width: 100,
          sort: 'disabled',
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'ncontrat',
          },
        },
        {
          label: "Fournisseur d'énergie",
          field: 'fournisseur',
          width: 100
        },
        {
          label: "Situation",
          field: 'situation',
          width: 100,
        },
        {
          label: 'Calibre disjoncteur',
          field: 'calibredisjonc',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'Calibre contacteur',
          field: 'calibrecontact',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'Puissance souscrite',
          field: 'puissance',
          width: 100,
        },
        {
          label: 'Type de compteur',
          field: 'typecompteur',
          width: 100,
        },
        {
          label: 'N° compteur',
          field: 'ncompteur',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'section arrivée',
          field: 'section',
          width: 100,
        },
        {
          label: 'Nombre de départ',
          field: 'nbrdepart',
          width: 100,
        },
        {
          label: 'Mise en service',
          field: 'miseservice',
          width: 100,
        },
        {
          label: 'Date création (UTC)',
          field: 'date',
          width: 100,
        },
        {
          label: 'Opérateur',
          field: 'operateur',
          width: 100,
        },
      ],
      totalCount:0,
      pictures:[],
      pictureDetail:"",
      spinner : true,
      spinner2 : true,
      showModal:false,
      infodepart : []
      }
    }
    componentWillMount(){
      if(sessionStorage.getItem('userVerify') == "Borio5213"  && (sessionStorage.getItem('role') == "coffret" || sessionStorage.getItem('role') == "full" || sessionStorage.getItem('rol2') == "coffret" || sessionStorage.getItem('role3') == "coffret")){
         
  
      }else{
        window.location.href = '/EP_Marrakech/Diagnostic/auth'
      }
     
        $("title").html("PROD'ECO")
        $(".link").attr('href',logo1)

    }

    async componentDidMount(){

      var Plpicture = $('.Plpicture')
      Plpicture.hide()
  
      fetch('https://integralhub.net:3005/getAllDiagCoffret').then((data)=>{
        
        let rows = this.state.row
        data.json().then((dataJson)=>{
           dataJson.forEach(item => {
             rows.push(item)
           })
  
           this.setState({
            data:{
              columns:this.state.columns,
              rows:rows
            },
            totalCount:rows.length,
            spinner2:false
            })
           
          })
        })
        
        $('.Pltable tbody').click((e)=>{
          let element = e.target.parentNode
          let coffret = element.childNodes[0].textContent
          let nomCoffret = element.childNodes[1].textContent
          let voie = element.childNodes[2].textContent
          let pictures = this.state.data.rows.filter(item =>{
            return item.ncoffret == coffret
          })
          let picturesName = pictures[0].photos.filter((val)=>{ return val.split('_')[1] == coffret })
          let depart = pictures[0].infodepart
             
          this.setState({
            pictures:picturesName,
            pictureDetail:'Coffret N°'+coffret,
            spinner:true,
            infodepart:depart
          })
  
          let pictureView = $('.Plpicture')
          pictureView.show(100)
          setTimeout(()=>{
            this.setState({
              spinner:false
            })
          },1000)
        })
  
      
        $('.mdb-datatable-filter input').attr('placeholder','Recherche')
    }

    handleShow(){
      this.setState({
        showModal:true
      })
    }
    

  render(){
    let {pictureDetail} = this.state
    return(
          <div className='Coffret'>
            <div className="Htitle">DIAGNOSTIQUE COFFRET ECLAIRAGE PUBLIC MARRAKECH</div>
            <div class="CCPCompteur">Nombre de coffret: {this.state.data.rows.length} / {this.state.totalCount}</div>
             <a href="/EP_Marrakech/Diagnostic"><img src={logoutIcon} className='logout2' width="30" height="30" title="TABLEAU DE BORD"/></a> 
            
             <div className="filtre">
               <table>
                 <tr>
                   <td><Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label className="label">N° Coffret</Form.Label>
    <Form.Control type="number" id="valCoff" onChange={()=>{
      let valCoff = $('#valCoff').val()
      if(valCoff == ""){
        window.location.reload();
      }
      let filter = this.state.row.filter(item => item.ncoffret == valCoff)
      this.setState({
        data:{
          columns:this.state.columns,
           rows:filter
        }
      })
    }} />
  </Form.Group></td>

                   <td>
                   <Button variant="outline-dark" className="buttonPl" size="sm" onClick={()=>{
                      window.location.reload();
                   }}>reset</Button>
                     </td>
                     <td>
                   <Button variant="outline-dark" className="buttonPl" size="sm" onClick={()=>{
                    function downloadCSV(csv, filename) {
                      var csvFile;
                      var downloadLink;
                  
                      // CSV file
                      csvFile = new Blob([csv], {type:"text/csv;charset=charset=utf-8;"});
                  
                      // Download link
                      downloadLink = document.createElement("a");
                  
                      // File name
                      downloadLink.download = filename;
                  
                      // Create a link to the file
                      downloadLink.href = window.URL.createObjectURL(csvFile);
                  
                      // Hide download link
                      downloadLink.style.display = "none";
                  
                      // Add the link to DOM
                      document.body.appendChild(downloadLink);
                  
                      // Click download link
                      downloadLink.click();
                  }
                  function exportTableToCSV(filename) {
                    var csv = [];
                    var rows = document.querySelectorAll(".Pltable tr");
                    
                    for (var i = 0; i < rows.length; i++) {
                        var row = [], cols = rows[i].querySelectorAll("td, th");
                        
                        for (var j = 0; j < cols.length; j++) 
                            row.push(cols[j].innerText);
                        
                        csv.push(row.join(";"));        
                    }
                
                    // Download CSV file
                    downloadCSV(csv.join("\n"), filename);
                }
                exportTableToCSV('Diag_PL_Marrakech.csv')
                   }}>CSV</Button>
                     </td>
                 </tr>
               </table>

             </div>
             <div className="PLview">
               <div className="Pltable">
                 {
                   this.state.spinner2 == true ? <Button variant="light" disabled>
                   <Spinner
                     as="span"
                     animation="grow"
                     role="status"
                     aria-hidden="true"
                   /> 
                    &nbsp; Patienter...
                 </Button> : null
                  } 

                  <MDBDataTableV5 hover entriesOptions={[10000,20000]} entries={100000} pagesAmount={4} data={this.state.data}  striped
                   bordered
                   small
                   searchTop
                   searchBottom={false}
                   paging={false}
                   searchingLabel="Nom coffret"
                   />
                 
               
               </div>
               <div className="Plpicture">
                 <p className="PLDetail">{this.state.pictureDetail}</p>
                 <Button variant="dark" onClick={()=>this.handleShow()}>info départs</Button>
                  
                 {
                   this.state.spinner == true ? <Spinner animation="border" role="status" className="spinner"><span className="sr-only">Loading...</span></Spinner> : this.state.pictures.map(el=>(
             
                    <div>
                      <a download={el+'.jpg'} href={'https://integralhub.net:3007/files/'+el}><img src={download} className="imgDown" width="30" height="30"/></a>
                   {/* <img width="270" height="250" src={'http://145.239.71.47:3006/files/'+el}/> */}
                    <InnerImageZoom width="270" height="250" src={'https://integralhub.net:3007/files/'+el}  />
                    </div>
                  )

                  )
                 }
                 
                
               </div>
             </div>
             <Modal show={this.state.showModal} onHide={()=>{
            this.setState({
              showModal:false
            })
          }}>
        <Modal.Header closeButton>
          <Modal.Title><h6>{this.state.pictureDetail}</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body className="plUpdateBody">
     
  
    {
      this.state.infodepart.map((el,index)=>(
        <Card>
        <Card.Body>
    <Card.Title>Départ N°{index+1}</Card.Title>
    <Card.Text>
      <span>Calibre: {el.calibre}</span><br/>
      <span>Section: {el.section}</span><br/>
      <span>R[A]: {el.R}</span><br/>
      <span>S[A]: {el.S}</span><br/>
      <span>T[A]: {el.T}</span><br/>
      <span>N° sous compteur: {el.souscompteur}</span><br/>
      <span>N° série modem: {el.serieModem}</span><br/>
      <span>N° série sim: {el.serieSim}</span>
    </Card.Text>
  </Card.Body>
       </Card>
      ))
    }
    
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>{
            this.setState({
              showModal:false
            })
          }}>
            Quitter
          </Button>
          
        </Modal.Footer>
      </Modal>
          </div> 
          
     )
  }



}

export default Coffret;