import React,{Component} from 'react'
import './AuthenDiag.css';
import Firebase from 'firebase'
import usericon from './user.svg'
import logo1 from "./Logo-prod'air.png"
import {Container,Row,Col,Button,InputGroup,FormControl,Form} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery';
class AuthenDiag extends Component{
  constructor(props){
    super(props)
    this.state = {
      loginError : "",
      login:"",
      pass:""
    }
  }
  signIn = ()=>{
    let userlogin = this.state.login+"@gmail.com";
    let userpass = this.state.pass;

    var firebaseConfig = {
      apiKey: "AIzaSyB1iqFH6BRKWq7DAG7_kxzFOkGBVyTkwIc",
      authDomain: "marrakechdiag-13edb.firebaseapp.com",
      projectId: "marrakechdiag-13edb",
      storageBucket: "marrakechdiag-13edb.appspot.com",
      messagingSenderId: "79463683560",
      appId: "1:79463683560:web:13b127bcfaf0c229ba805d",
      measurementId: "G-YQFPBCH5WH"
    };
    
  
    (!Firebase.apps.length) ? Firebase.initializeApp(firebaseConfig) : Firebase.app();
    //Firebase.initializeApp(firebaseConfig)
    Firebase.analytics()
    console.log(this.state.login.toLowerCase())
    Firebase.auth().signInWithEmailAndPassword(userlogin,userpass).then((succes)=>{
      var rootRef = Firebase.database().ref()
      var data = rootRef.child("autorisation").orderByChild('name').equalTo(this.state.login.toLowerCase())
      console.log(data)
      data.on('child_added',(snap)=>{
        let role = snap.child('role').val()
        let role2 = snap.child('role2').val()
        let role3 = snap.child('role3').val()

        sessionStorage.setItem('user',snap.child('name').val());
          sessionStorage.setItem('userVerify','Borio5213');
          sessionStorage.setItem('role',role);
          if(role2){
            sessionStorage.setItem('role2',role2);
          }
          if(role3){
            sessionStorage.setItem('role3',role3);
          }
        window.location.href = '/EP_Marrakech/Diagnostic'
      })
          

    }).catch((error)=>{
      this.setState({loginError:"Veuillez entrée des indentifiants valides"})
      console.log('error')
    })
    
   }

   handleLoginChange = (e)=>{
    this.setState({login: e.target.value});
    } 
   handlePasswordChange = (e)=>{
    this.setState({pass: e.target.value});
   }

   componentWillMount(){
      $("title").html("PROD'ECO")
      $(".link").attr('href',logo1)
   }

   render(){
    let {loginError,login,pass} = this.state
    return(
      
      <div className='authen1'>
        <img src={logo1} className="logoAuthe" width="200" height="200" />
       <div className="containt">    
 <Row className="header">
      <img width="50" height="50" src={usericon}></img>
  </Row>
  <Row className="login">
       <Form>
        <Form.Group controlId="formLogin">
        <Form.Label>Identifiant</Form.Label>
        <Form.Control type="text" placeholder="Login" value={login} onChange={this.handleLoginChange} />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
           <Form.Label>Mot de passe</Form.Label>
           <Form.Control type="password" placeholder="Mot de passe" value={pass} onChange={this.handlePasswordChange}/>
        </Form.Group>
        <Button variant="dark" type="Button" onClick={this.signIn} block>
          Se connecter
       </Button>
       <Form.Text className="error">
           <span>{loginError}</span>
        </Form.Text>
     </Form>
</Row>
       </div>
      </div>
      
    )
  }
}

export default AuthenDiag;