import React,{Component} from 'react'
import './CEP.css';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Form,Button,Modal,Spinner} from 'react-bootstrap'
import { MDBDataTableV5 } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'mdbreact/dist/css/mdb.css'
import logoutIcon from './logout.svg'
import download from './download.svg'
import logo1 from "./Logo-prod'air.png"
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

class PL extends Component{
  constructor(props){
    super(props)
    this.state = {
      data:{
        columns:[],
        rows:[]
      },
      value:[],
      row:[],
      columns:[
        {
          label: 'N° Coffret',
          field: 'ncoffret',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Nom Coffret',
          field: 'nomcoffret',
          width: 150,
          sort: 'asc',
        },
        {
          label: 'ID Voie',
          field: 'idvoie',
          width: 100,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'idvoie',
          },
          sort: 'asc',
        },
        {
          label: 'Largeur (m)',
          field: 'largeur',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'Interdistance (m)',
          field: 'interdist',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'Observation',
          field: 'observation',
          width: 300,
          sort: 'disabled',
        },
        {
          label: 'Date création (UTC)',
          field: 'date',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Opérateur',
          field: 'operateur',
          sort: 'asc',
          width: 100,
        }
      ],
      totalCount:0,
      pictures:[],
      pictureDetail:"",
      spinner : true,
      spinner2 : true,
    }
  }
  componentWillMount(){
    if(sessionStorage.getItem('userVerify') == "Borio5213" && (sessionStorage.getItem('role') == "cep" || sessionStorage.getItem('role') == "full" || sessionStorage.getItem('role2') == "cep" || sessionStorage.getItem('role3') == "cep")){
       

    }else{
      window.location.href = '/EP_Marrakech/Diagnostic/auth'
    }
    
      $("title").html("PROD'ECO")
      $(".link").attr('href',logo1)

      
   
  }
  async componentDidMount(){

    var Plpicture = $('.Plpicture')
    Plpicture.hide()

    fetch('https://integralhub.net:3005/getIDVoirieAll').then((data)=>{
      
      let rows = this.state.row
      data.json().then((dataJson)=>{
         dataJson.forEach(item => {
           rows.push(item)
         })

         this.setState({
          data:{
            columns:this.state.columns,
            rows:rows
          },
          totalCount:rows.length,
          spinner2:false
          })
         
        })
      })
      
      $('.Pltable tbody').click((e)=>{
        let element = e.target.parentNode
        let coffret = element.childNodes[0].textContent
        let nomCoffret = element.childNodes[1].textContent
        let voie = element.childNodes[2].textContent
        let pictures = this.state.data.rows.filter(item =>{
          return item.ncoffret == coffret && item.idvoie == voie
        })
        let picturesName = pictures[0].photoname

        this.setState({
          pictures:picturesName,
          pictureDetail:'Coffret n°'+coffret+' / Voie n°'+voie,
          spinner:true
        })

        let pictureView = $('.Plpicture')
        pictureView.show(100)
         console.log('click')
        setTimeout(()=>{
          this.setState({
            spinner:false
          })
        },1000)
      })

      $('.mdb-datatable-filter input').attr('placeholder','Recherche par nom coffret')

  }


  render(){
    return(
          <div className='PL'>
            <div className="Htitle">CONCEPTION ECLAIRAGE PUBLIC MARRAKECH</div>
            <div class="PLCompteur">Nombre de voie: {this.state.data.rows.length} / {this.state.totalCount}</div>
             <a href="/EP_Marrakech/Diagnostic"><img src={logoutIcon} className='logout2' width="30" height="30" title="TABLEAU DE BORD"/></a>
             <div className="filtre">
               <table>
                 <tr>
                   <td><Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label className="label">N° Coffret</Form.Label>
    <Form.Control type="number" id="valCoff" placeholder="" onChange={()=>{
      let valCoff = $('#valCoff').val()
      if(valCoff == ""){
        window.location.reload();
      }
      let filter = this.state.row.filter(item => item.ncoffret == valCoff)
      this.setState({
        data:{
          columns:this.state.columns,
           rows:filter
        }
      })
    }} />
  </Form.Group></td>

                   <td>
                   <Button variant="outline-dark" className="buttonPl" size="sm" onClick={()=>{
                      window.location.reload();
                   }}>reset</Button>
                     </td>
                     <td>
                   <Button variant="outline-dark" className="buttonPl" size="sm" onClick={()=>{
                    function downloadCSV(csv, filename) {
                      var csvFile;
                      var downloadLink;
                  
                      // CSV file
                      csvFile = new Blob([csv], {type:"text/csv;charset=charset=utf-8;"});
                  
                      // Download link
                      downloadLink = document.createElement("a");
                  
                      // File name
                      downloadLink.download = filename;
                  
                      // Create a link to the file
                      downloadLink.href = window.URL.createObjectURL(csvFile);
                  
                      // Hide download link
                      downloadLink.style.display = "none";
                  
                      // Add the link to DOM
                      document.body.appendChild(downloadLink);
                  
                      // Click download link
                      downloadLink.click();
                  }
                  function exportTableToCSV(filename) {
                    var csv = [];
                    var rows = document.querySelectorAll(".Pltable tr");
                    
                    for (var i = 0; i < rows.length; i++) {
                        var row = [], cols = rows[i].querySelectorAll("td, th");
                        
                        for (var j = 0; j < cols.length; j++) 
                            row.push(cols[j].innerText);
                        
                        csv.push(row.join(";"));        
                    }
                
                    // Download CSV file
                    downloadCSV(csv.join("\n"), filename);
                }
                exportTableToCSV('Diag_CEP_Marrakech.csv')
                   }}>CSV</Button>
                     </td>
                 </tr>
               </table>

             </div>
             <div className="PLview">
               <div className="Pltable">
                 {
                   this.state.spinner2 == true ? <Button variant="light" disabled>
                   <Spinner
                     as="span"
                     animation="grow"
                     role="status"
                     aria-hidden="true"
                   /> 
                    &nbsp; Patienter...
                 </Button> : null
                  } 

                  <MDBDataTableV5 hover entriesOptions={[10000,20000]} entries={100000} pagesAmount={4} data={this.state.data}  striped
                   bordered
                   small
                   searchTop
                   searchBottom={false}
                   paging={false}
                   searchingLabel="Nom coffret"
                   />
                 
               
               </div>
               <div className="Plpicture">
                 <p className="PLDetail">{this.state.pictureDetail}</p>
                 {
                   this.state.spinner == true ? <Spinner animation="border" role="status" className="spinner"><span className="sr-only">Loading...</span></Spinner> : this.state.pictures.map(el=>(
                    <div>
                      <a download={el+'.jpg'} href={'https://integralhub.net:3007/files/'+el}><img src={download} className="imgDown" width="30" height="30"/></a>
                   {/* <img width="270" height="250" src={'http://145.239.71.47:3006/files/'+el}/> */}
                    <InnerImageZoom width="270" height="250" src={'https://integralhub.net:3007/files/'+el}  />
                    </div>
                  )

                  )
                 }
                 
                
               </div>
             </div>
           
          </div>   
     )
  }


}

export default PL;