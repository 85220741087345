import React,{Component} from 'react'
import Map from './map'
import './PL.css';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Form,Button,Modal,Spinner} from 'react-bootstrap'
import { MDBDataTableV5 } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'mdbreact/dist/css/mdb.css'
import logoutIcon from './logout.svg'
import download from './download.svg'
import logo1 from "./Logo-prod'air.png"
import haa from './Logo-HAA.png'
import nameCoffret from './nameCoffret.json'
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import 'leaflet/dist/leaflet.css'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';




class PL extends Component{
  constructor(props){
    super(props)
    this.state = {
      data:{
        columns:[],
        rows:[]
      },
      data2:{
        columns:[],
        rows:[]
      },
      value:[],
      row:[],
      columns:[
        {
          label: 'N° Coffret',
          field: 'ncoffret',
          sort: 'asc',
          width: 50,
        },
        {
          label: 'N° Contrat',
          field: 'ncontrat',
          sort: 'asc',
          width: 50,
        },
        {
          label: 'Nom Coffret',
          field: 'nomcoffret',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'N° Départ',
          field: 'ndepart',
          width: 50,
        },
        {
          label: 'N° PL',
          field: 'npl',
          width: 50,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'npl',
          },
          sort: 'asc',
        },
        {
          label: 'Hauteur (m)',
          field: 'hauteur',
          sort: 'disabled',
          width: 50,
        },
        {
          label: 'Type luminaire',
          field: 'type_luminaire',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'Type support',
          field: 'type_support',
          width: 300,
        },
        {
          label: 'Nombre luminaire',
          field: 'nbr_luminaire',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'Marque',
          field: 'marque',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'Puissance (W)',
          field: 'puissance',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'Anomalies support',
          field: 'anomaliessup',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'Anomalies luminaire',
          field: 'anomalies',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'Date création (UTC)',
          field: 'date',
          width: 200,
          sort: 'asc',
        },
        {
          label: 'Opérateur',
          field: 'operateur',
          width: 200,
          sort: 'disabled',
        },
      ],
      totalCount:0,
      pictures:[],
      pictureDetail:"",
      spinner : true,
      spinner2 : true,
      repportData:{
        anomaliessup:[],
        anomalies:[],
        photos:[]
      },
      map:null,
      mapref:null,
      reloadMap:false,
      namecoffret:'',
      showModal:false
    }
  }
  
  componentWillMount(){
    if(sessionStorage.getItem('userVerify') == "Borio5213" && (sessionStorage.getItem('role') == "PL" || sessionStorage.getItem('role') == "full" || sessionStorage.getItem('role2') == "PL" || sessionStorage.getItem('role3') == "PL")){
       

    }else{
      window.location.href = '/EP_Marrakech/Diagnostic/auth'
    }
    
      $("title").html("PROD'ECO")
      $(".link").attr('href',logo1)
   
  }
  async componentDidMount(){

    var Plpicture = $('.Plpicture')
    Plpicture.hide()

    fetch('https://integralhub.net:3005/getPLAll').then((data)=>{
      
      let rows = this.state.row
      data.json().then((dataJson)=>{
         dataJson.forEach(item => {
           rows.push(item)
         })

         this.setState({
          data:{
            columns:this.state.columns,
            rows:rows
          },
          data2:{
            columns:this.state.columns,
            rows:rows
          },
          totalCount:rows.length,
          spinner2:false
          })
         
        })
      })
      
      $('.Pltable tbody').click((e)=>{
        let element = e.target.parentNode
        let coffret = element.childNodes[0].textContent
        let depart = element.childNodes[3].textContent
        let pl = element.childNodes[4].textContent
        let pictures = this.state.data.rows.filter(item =>{
          return item.ncoffret == coffret && item.ndepart == depart && item.npl == pl
        })
        let picturesName = pictures[0].photos
        console.log(pictures[0])
        let nameCf
        try{
         nameCf = nameCoffret.SUIVI.filter((el)=>{return el.NCoffret == coffret})[0].NomCoffret
        }catch{
          nameCf = ""
        }
        this.setState({
          pictures:picturesName,
          pictureDetail:'Coffret:'+coffret+' / Départ:'+depart+' / PL:'+pl,
          spinner:true,
          repportData:pictures[0],
          reloadMap:true,
          namecoffret: nameCf
        })

     
        let pictureView = $('.Plpicture')
        pictureView.show(100)
         console.log('click')
        setTimeout(()=>{
          this.setState({
            spinner:false
          })
        },1000)
      })

      $('.repportOut').click(()=>{
        $('.repportDiv').css('visibility','hidden')
      })  

      $('.mdb-datatable-filter input').attr('placeholder','Recherche')
  }

  handleShow(){
    this.setState({
      showModal:true
    })
  }

  render(){
    let postionMarker = [this.state.repportData.y, this.state.repportData.x]

    let datef = ""
    if(this.state.repportData.date){
      const date = this.state.repportData.date
      const date2 = date.split('.')
      const date3 = date2[0].split('T')
      const date4 = date3[0].split("-").reverse().join("/")
      datef = date4+" à "+date3[1]+" (UTC)"
     
    }
    return(
          <div className='PL'>

            <div className="repportDiv">
            <span className="repportOut">&#10008;</span>
            
            <div className="repportView" id="divToPrint" ref={el => (this.componentRef = el)}>
              <img src={haa} width="170" height="150" style={{position:'relative',left:'34px',top:'10px'}}/>
              <br/>
            <h3 style={{fontWeight:'bold',textAlign:'center',textDecoration:'underline'}}>RAPPORT POINT LUMINEUX</h3>
            
              <table className="repportTable">
                <tr><td ><span className="detTitle">Nom Coffret</span>: {this.state.namecoffret} </td><td><span className="detTitle">N° Coffret</span>: {this.state.repportData.ncoffret}</td></tr>
                <tr><td ><span className="detTitle">N° Départ</span>: {this.state.repportData.ndepart}</td><td ><span className="detTitle">N° PL</span>: {this.state.repportData.npl}</td></tr>
                <tr><td><span className="detTitle">Type Support</span>: {this.state.repportData.type_support}</td><td><span className="detTitle">Anomalie Support</span>: {this.state.repportData.anomaliessup.toString()}</td></tr>
                <tr><td><span className="detTitle">Marque Luminaire</span>: {this.state.repportData.marque}</td><td><span className="detTitle">Anomalie Luminaire</span>: {this.state.repportData.anomalies.toString()}</td></tr>
                <tr><td colspan="2"><span className="detTitle">Date contrôle</span>: {datef ? datef : ""}</td></tr>
                <tr><td colspan="2"><h4 style={{fontWeight:'bold',textAlign:'center',textDecoration:'underline'}}>Situation Géographique</h4></td></tr>
               <tr><td colspan="2">{this.state.repportData.x && this.state.repportData.y ?<Map center={postionMarker} zoom={16} load={this.state.reloadMap}/>:null} </td></tr>
              <tr><td colspan="2"><h4 style={{fontWeight:'bold',textAlign:'center',textDecoration:'underline'}}>Photos</h4></td></tr>
    <tr>
      <td colspan="2">
    <div class="repportImg">          
{
  this.state.repportData.photos.map(el=>(
    <div>
    <img style={{margin:'5px'}} width="320" height="500" src={'https://integralhub.net:3007/files/'+el} />
    </div>
  )

  )
}
</div>
</td>
</tr>
   </table>

            </div>

            </div>
            <div className="Htitle">DIAGNOSTIQUE POINT LUMINEUX ECLAIRAGE PUBLIC MARRAKECH</div>
            <div class="PLCompteur">Nombre de Point Luminieux: {this.state.data.rows.length} / {this.state.totalCount}</div>
             <a href="/EP_Marrakech/Diagnostic"><img src={logoutIcon} className='logout2' width="30" height="30" title="TABLEAU DE BORD"/></a>
             <div className="filtre">
               <table>
                 <tr>
                   <td><Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label className="label">N° Coffret</Form.Label>
    <Form.Control type="number" id="valCoff" onChange={()=>{
      let valCoff = $('#valCoff').val()
      if(valCoff == ""){
        window.location.reload();
      }
      let filter = this.state.row.filter(item => item.ncoffret == valCoff)
      this.setState({
        data:{
          columns:this.state.columns,
           rows:filter
        }
      })
    }} />
  </Form.Group></td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                   <td>
                   <Form.Group>
                  <Form.Label className="label">Selection</Form.Label>
                    <Form.Control as="select" onChange={()=>{
                       let values = {
                        type_support:["","candeladre cylindro-conique","câble acier","console sur facade","console sur poteau beton","console sur poteau bois","pylône"],
                        marque:["","Vmax","S line","C line","Urba lite","Retrofit","HFL","Novatilu","Cobra","NI","Mais","Tilal","Feuille"],
                        anomaliessup:["","console à déposer","console à changer","console à redresser","candéladre à redresser"],
                        anomalies:["","luminaire incliné","luminaire inexistant","HS","Obstacle"]
                      }
                  
                      let select = values[$('#Opt').val()]
                      if(select){
                        this.setState({
                          value:select
                        })
                      }
                      this.setState({
                        value:select
                      })
                    }} id="Opt">
      <option value=""></option>
      <option value="type_support">Type support</option>
      <option value="marque">Marque</option>
      <option value="anomaliessup">Anomalies support</option>
      <option value="anomalies">Anomalies luminaire</option>
                    </Form.Control>
               </Form.Group>
                   </td>
                   <td>
                   <Form.Group>
                  <Form.Label className="label">Valeur</Form.Label>
                    <Form.Control as="select" id="Val" onChange={()=>{
                      let select = $('#Opt').val()
                      let val = $('#Val').val()
                      let valCoff = $('#valCoff').val()
                      let filter = this.state.row.filter(item=>{
                           switch(select){
                             case 'type_support':
                              return valCoff ? item.type_support == val && item.ncoffret == valCoff : item.type_support == val
                             break;
                             case 'marque':
                              return  valCoff ? item.marque == val && item.ncoffret == valCoff : item.marque == val
                             break;
                             case 'anomaliessup':
                              return valCoff ? (item.anomaliessup[0] == val || item.anomaliessup[1] == val)  && item.ncoffret == valCoff : item.anomaliessup[0] == val || item.anomaliessup[1] == val
                             break;
                             case 'anomalies':
                              return valCoff ? (item.anomalies[0] == val || item.anomalies[1] == val)  && item.ncoffret == valCoff : item.anomalies[0] == val || item.anomalies[1] == val
                             break;


                            }
                      })
                      this.setState({
                        data:{
                          columns:this.state.columns,
                           rows:filter
                        }
                      })
                    }}>
                    {
                      this.state.value ? this.state.value.map(item=>(<option value={item}>{item}</option>)) : ""
                    }
                    </Form.Control>
               </Form.Group>
                   </td>
                   <td>
                   <Button variant="outline-dark" className="buttonPl" size="sm" onClick={()=>{
                      window.location.reload();
                   }}>reset</Button>
                     </td>
                     <td>
                   <Button variant="outline-dark" className="buttonPl" size="sm" onClick={()=>{
                    function downloadCSV(csv, filename) {
                      var csvFile;
                      var downloadLink;
                  
                      // CSV file
                      csvFile = new Blob([csv], {type:"text/csv;charset=charset=utf-8;"});
                  
                      // Download link
                      downloadLink = document.createElement("a");
                  
                      // File name
                      downloadLink.download = filename;
                  
                      // Create a link to the file
                      downloadLink.href = window.URL.createObjectURL(csvFile);
                  
                      // Hide download link
                      downloadLink.style.display = "none";
                  
                      // Add the link to DOM
                      document.body.appendChild(downloadLink);
                  
                      // Click download link
                      downloadLink.click();
                  }
                  function exportTableToCSV(filename) {
                    var csv = [];
                    var rows = document.querySelectorAll(".Pltable tr");
                    
                    for (var i = 0; i < rows.length; i++) {
                        var row = [], cols = rows[i].querySelectorAll("td, th");
                        
                        for (var j = 0; j < cols.length; j++) 
                            row.push(cols[j].innerText);
                        
                        csv.push(row.join(";"));        
                    }
                
                    // Download CSV file
                    downloadCSV(csv.join("\n"), filename);
                }
                exportTableToCSV('Diag_PL_Marrakech.csv')
                   }}>Filter to CSV</Button>
                     </td>
                     <td>
                   <Button variant="outline-dark" className="buttonPl" size="sm" onClick={()=>{
                    function downloadCSV(csv, filename) {
                      var csvFile;
                      var downloadLink;
                  
                      // CSV file
                      csvFile = new Blob([csv], {type:"text/csv;charset=charset=utf-8;"});
                  
                      // Download link
                      downloadLink = document.createElement("a");
                  
                      // File name
                      downloadLink.download = filename;
                  
                      // Create a link to the file
                      downloadLink.href = window.URL.createObjectURL(csvFile);
                  
                      // Hide download link
                      downloadLink.style.display = "none";
                  
                      // Add the link to DOM
                      document.body.appendChild(downloadLink);
                  
                      // Click download link
                      downloadLink.click();
                  }
                  function exportTableToCSV(filename) {
                    var csv = [];
                    var rows = document.querySelectorAll(".Pltable2 tr");
                    
                    for (var i = 0; i < rows.length; i++) {
                        var row = [], cols = rows[i].querySelectorAll("td, th");
                        
                        for (var j = 0; j < cols.length; j++) 
                            row.push(cols[j].innerText);
                        
                        csv.push(row.join(";"));        
                    }
                
                    // Download CSV file
                    downloadCSV(csv.join("\n"), filename);
                }
                exportTableToCSV('Diag_PL_Marrakech.csv')
                   }}>ALL to CSV</Button>
                     </td>
                 </tr>
               </table>

             </div>
             <div className="PLview">
               <div className="Pltable">
                 {
                   this.state.spinner2 == true ? <Button variant="light" disabled>
                   <Spinner
                     as="span"
                     animation="grow"
                     role="status"
                     aria-hidden="true"
                   /> 
                    &nbsp; Patienter...
                 </Button> : null
                  } 

                  <MDBDataTableV5 hover entriesOptions={[300,400]} entries={300} pagesAmount={4} data={this.state.data}  striped
                   bordered
                   small
                   searchTop
                   searchBottom={false}
                   paging={true}
                   />
                 
               
               </div>
               <div className="Pltable2" style={{display:'none'}}>

<MDBDataTableV5 hover entriesOptions={[300,400]} entries={200000} pagesAmount={4} data={this.state.data2}  striped
 bordered
 small
 searchTop
 searchBottom={false}
 paging={true}
 />

</div>
               <div className="Plpicture">

                 <p className="PLDetail">{this.state.pictureDetail}</p>

              <table className="tableUpdate">
                <tr>
                  <td><Button variant="danger" onClick={()=>this.handleShow()}>Modifier</Button></td>
                  <td> <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <Button variant="light" onClick={()=>{
              console.log(this.state.repportData)
              $('.repportDiv').css('visibility','visible')
              
            }}>Rapport</Button>
          }}
          content={() => this.componentRef}
          documentTitle={"Ref_"+this.state.repportData.ncoffret+"_"+this.state.repportData.ndepart+"_"+this.state.repportData.npl}
        /></td>
                </tr>
                </table>   
           
                 
                 {
                   this.state.spinner == true ? <Spinner animation="border" role="status" className="spinner"><span className="sr-only">Loading...</span></Spinner> : this.state.pictures.map(el=>(
                    <div>
                      <a download={el+'.jpg'} href={'https://integralhub.net:3007/files/'+el}><img src={download} className="imgDown" width="30" height="30"/></a>
                   {/* <img width="270" height="250" src={'http://145.239.71.47:3006/files/'+el}/> */}
                    <InnerImageZoom width="270" height="250" src={'https://integralhub.net:3007/files/'+el}  />
                    </div>
                  )

                  )
                 }
                 
                
               </div>
             </div>
             <Modal show={this.state.showModal} onHide={()=>{
            this.setState({
              showModal:false
            })
          }}>
        <Modal.Header closeButton>
          <Modal.Title><h6>Modification {this.state.pictureDetail}</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body className="plUpdateBody">
        <Form>
            <Form.Group>
              <Form.Label>N° Coffret</Form.Label>
              <Form.Control type="number" defaultValue={this.state.repportData.ncoffret} id="Ncoffret"/>
            </Form.Group>
            <Form.Group>
              <Form.Label>N° Départ</Form.Label>
              <Form.Control type="number" defaultValue={this.state.repportData.ndepart} id="Ndepart"/>
            </Form.Group>
            <Form.Group>
              <Form.Label>N° PL</Form.Label>
              <Form.Control type="number" defaultValue={this.state.repportData.npl} id="Npl"/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Hauteur</Form.Label>
              <Form.Control type="number" defaultValue={this.state.repportData.hauteur} id="hauteur"/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Type luminaire</Form.Label>
              {/* <Form.Control type="text" defaultValue={this.state.repportData.type_luminaire} id="TypeLuminaire"/> */}
              <Form.Control as="select" id="TypeLuminaire" defaultValue={this.state.repportData.type_luminaire}>
              <option value="candeladre cylindro-conique">Candeladre cylindro-conique</option>
              <option value="standard">Standard</option>
          <option value="décoratif">Décoratif</option>
          <option value="projecteur">Projecteur</option>
          <option value="spot">Spot</option>
                    </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Type Support</Form.Label>
              {/* <Form.Control type="text" defaultValue={this.state.repportData.type_support} id="TypeSupport"/> */}
              <Form.Control as="select" id="TypeSupport" defaultValue={this.state.repportData.type_support}>
              <option value="candeladre cylindro-conique">Candeladre cylindro-conique</option>
          <option value="câble acier">Câble en acier</option>
          <option value="console sur facade">Console sur façade</option>
          <option value="console sur poteau beton">Console sur poteau beton</option>
          <option value="console sur poteau bois">Console sur poteau bois</option>
          <option value="pylône">Pylône</option>
                    </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Nombre luminaire</Form.Label>
              <Form.Control type="text" defaultValue={this.state.repportData.nbr_luminaire} id="nbrLuminaire"/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Marque</Form.Label>
              {/* <Form.Control type="text" defaultValue={this.state.repportData.marque} id="Marque"/> */}
              <Form.Control as="select" id="Marque" defaultValue={this.state.repportData.marque}>
              <option value="Vmax">Vmax</option>
          <option value="S line">S line</option>
          <option value="C line">C line</option>
          <option value="Urba lite">Urba lite</option>
          <option value="Retrofit">Retrofit</option>
          <option value="HFL">HFL</option>
          <option value="Novatilu">Novatilu</option>
          <option value="Cobra">Cobra</option>
          <option value="NI">NI</option>
          <option value="Mais">Mais</option>
          <option value="Tilal">Tilal</option>
          <option value="Feuille">Feuille</option>
                    </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Puissance</Form.Label>
              <Form.Control type="text" defaultValue={this.state.repportData.puissance} id="Puissance"/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Anomalies support (Ctrl+Select)</Form.Label>
              {/* <Form.Control type="text" defaultValue={this.state.repportData.anomaliessup} id="AnoSupport"/> */}
              <Form.Control as="select" id="AnoSupport" multiple="true" defaultValue={this.state.repportData.anomaliessup}>
              <option value=""></option>
              <option value="console à déposer">Console à déposer</option>
          <option value="console à changer">Console à changer</option>
          <option value="console à redresser">Console à redresser</option>
          <option value="candéladre à redresser">Candéladre à redresser</option>
                    </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Anomalies luminaire (Ctrl+Select)</Form.Label>
              {/* <Form.Control type="text" defaultValue={this.state.repportData.anomalies} id="AnoLuminaire"/> */}
              <Form.Control as="select" id="AnoLuminaire" multiple="true" defaultValue={this.state.repportData.anomalies}>
              <option value=""></option>
              <option value="luminaire incliné">Luminaire incliné</option>
          <option value="luminaire inexistant">Luminaire inexistant</option>
          <option value="HS">HS</option>
          <option value="Obstacle">Obstacle</option>
                    </Form.Control>
            </Form.Group>
            </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>{
            this.setState({
              showModal:false
            })
          }}>
            Annuler
          </Button>
          <Button variant="primary" type="submit" onClick={()=>{
            var ncoffret = $('#Ncoffret').val();
            var ndepart = $('#Ndepart').val();
            var npl = $('#Npl').val();
            var newnpl = $('#Npl').val();
            var typeSupport = $('#TypeSupport').val();
            var hauteur = $('#hauteur').val();
            var nbrLumi = $('#nbrLuminaire').val();
            var typeLumi = $('#TypeLuminaire').val();
            var puissance = $('#Puissance').val();
            var orientation = this.state.repportData.orientation
            var marque = $('#Marque').val();
            var anomalie = $('#AnoLuminaire').val()
            // if($('#AnoLuminaire').val()){
            //   anomalie = $('#AnoLuminaire').val().split(',');
            // }else{
            //   anomalie = []
            // }
            
            let AnomalieLumArray = []
          if(anomalie.length>0){
            anomalie.map((item)=>{
        AnomalieLumArray.push('"'+item+'"')
     })
         }
            let AnomalieLumFormat = '{'+AnomalieLumArray.toString()+'}'

            var AnomalieSup = $('#AnoSupport').val()
            // if($('#AnoSupport').val()){
            //   AnomalieSup = $('#AnoSupport').val().split(',');
            // }else{
            //   AnomalieSup = []
            // }
            let AnomalieSupArray = []
            if(AnomalieSup.length>0){
              AnomalieSup.map((item)=>{
                AnomalieSupArray.push('"'+item+'"')
             })
            }
            let AnomalieSupFormat = '{'+AnomalieSupArray.toString()+'}'

            var photos = this.state.repportData.photos
            let photosArray = []
             if(photos.length>0){
             photos.map((item)=>{
             photosArray.push('"'+item+'"')
               })
              }
             let photoFormat = '{'+photosArray.toString()+'}'
            var x = this.state.repportData.x
            var y = this.state.repportData.y
            var operateur = this.state.repportData.operateur
            fetch('https://integralhub.net:3005/updatePL/'+ncoffret+'/'+ndepart+'/'+npl+'/'+newnpl+'/'+typeSupport+'/'+hauteur+'/'+nbrLumi+'/'+typeLumi+'/'+puissance+'/'+orientation+'/'+marque+'/'+AnomalieLumFormat+'/'+AnomalieSupFormat+'/'+photoFormat+'/'+x+'/'+y+'/'+operateur).then((data)=>{
              this.setState({
                showModal:false
              })
              alert('Mise à jour sauvegarder')
              window.location.reload();

           }).catch((error)=>{
             alert('Erreur de mise à jour')
           })
          }}>
            Appliquer
          </Button>
        </Modal.Footer>
      </Modal>
           
          </div>   
     )
  }


}

export default PL;