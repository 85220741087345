import React,{Component} from 'react'
import $ from 'jquery'
import './header.css';
import offIcon from './off.svg'
import logo from './logo.png'
import logoM from './logoMasque.png'
import Slogan from './Slogan.png'
class Header extends Component{
  constructor(props){
    super(props)
    this.state = {
       user:""
    }
  }
  componentWillMount(){
   
    if(sessionStorage.getItem('userVerify') == "Borio5213"){
      this.setState({
        user:sessionStorage.getItem('user')
      }) 

    }else{
      window.location.href = '/EP_Marrakech/Dashboard/auth'
    }
  }
disconnect(){
  sessionStorage.setItem('userVerify','X#ghuurRh');
  sessionStorage.setItem('admin','popiropop');
  window.location.href = '/EP_Marrakech/Dashboard/auth'
}
  render(){
    let {user} = this.state
    return(
      <div className='Header'>
        <span className="user">Bienvenue, {user}</span>
        <img width="25" height="25" src={offIcon} className="disconnect1" onClick={this.disconnect} title="déconnexion"/>
        <img width="250" height="30" src={Slogan} className="slogan"/>
       <div className="Entete"><img height="30" width="25" src={logoM} title=""/></div>
      </div>
    )
  }
  componentDidMount(){
  }
}

export default Header;