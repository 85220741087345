import React,{Component} from 'react'
import './Form.css';
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap'
import {Form as Formu,Button,Modal} from 'react-bootstrap'

class Form extends Component{
  constructor(props){
    super(props)
    this.state = {
      showModal:false
    }
  }

  handleShow(){
    this.setState({
      showModal:true
    })
  }

  handleClose(){
    this.setState({
      showModal:false
    })
  }

  render(){
    let dataElement = this.props.dataElement
    return(
      <div className='Form'>
            <Formu>
            <Formu.Group>
              <Formu.Label>N° CONTRAT</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].Contrat} id="Contrat"/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>CCP</Formu.Label>
              <Formu.Control type="text" defaultValue={dataElement[0].CCP} id="CCP"/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>ZONE</Formu.Label>
              <Formu.Control type="text" defaultValue={dataElement[0].ZONE} id="ZONE"/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>NPL</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].NPL} id="NPL"/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>P(kW)</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].P} id="P"/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>Consommation théorique (kWh)</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].Consommation_théorique} disabled/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>NPLnv</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].NPLnv} id="NPLnv"/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>NPL_AN</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].NPL_AN} id="NPL_AN"/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>NPLTOT</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].NPLTOT} disabled/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>NPL REMPLACES</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].NPL_REM} id="NPL_REM"/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>HFL</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].HFL} id="HFL"/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>EXTENSION H.A</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].EXTENSION} disabled/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>TAUX REALISATION</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].REALISATION} disabled/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>Ptotale(kW)</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].Ptotale} id="Ptotale"/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>Pnouveaux.luminaires(kW)</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].Pnouveaux} id="Pnouveaux"/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>Panciens.luminaires(kW)</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].Panciens} disabled/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>CONSOMMATION ANNUELLE (kWh)</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].CONSO_an} disabled/>
            </Formu.Group>
            <Formu.Group>
              <Formu.Label>ECONOMIE D'ENERGIE</Formu.Label>
              <Formu.Control type="number" defaultValue={dataElement[0].ECO_Ener} disabled/>
            </Formu.Group>

            <Button variant="dark" type="button" className="btn-block FormButton">
              Mettre à jour
            </Button>
          </Formu>
          <Modal show={this.state.showModal} onHide={()=>{
            this.setState({
              showModal:false
            })
          }}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de la mise à jour</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous poursuivre la mise à jour?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>{
            this.setState({
              showModal:false
            })
            window.location.reload();
          }}>
            Annuler
          </Button>
          <Button variant="primary" id="savedata" type="submit">
            Continuer
          </Button>
        </Modal.Footer>
      </Modal>
       
        
      </div>
    )
  }

  componentDidMount(){
    $('Form').on('focus', 'input[type=number]', function (e) {
      $(this).on('wheel.disableScroll', function (e) {
        e.preventDefault()
      })
    })
    
    $(".FormButton").click(()=>{
    var Contrat = $('#Contrat').val()
    var CCP = $('#CCP').val()
    
    var NPL = $('#NPL').val()
    var NPL_AN = $('#NPL_AN').val()
    var NPL_REM = $('#NPL_REM').val()
    var NPLnv = $('#NPLnv').val()
    var P = $('#P').val()
    var Pnouveaux = parseFloat($('#Pnouveaux').val()).toFixed(2)
    var Ptotale = $('#Ptotale').val()
    var ZONE = $('#ZONE').val()
    var HFL = $('#HFL').val()
    if(HFL ==''){
      HFL = 0
    }
    var REALISATION = parseFloat(NPL_REM / NPL).toFixed(2)
    var Consommation_théorique = parseFloat(P*4330*1.25).toFixed(2)
    var Panciens = parseFloat(Ptotale - Pnouveaux).toFixed(2)
    var NPLTOT = parseInt(NPL_AN) + parseInt(NPLnv)
    var CONSO_an = parseFloat((Panciens*4330*1.25)+(Pnouveaux*4000)).toFixed(2)
    var ECO_Ener = parseFloat((Consommation_théorique - CONSO_an) / Consommation_théorique).toFixed(2)
    var EXTENSION = NPLnv - NPL_REM - HFL
    var operateur = sessionStorage.getItem('operateur')
    var operateurVerif = operateur.split('.').length > 1 ? true : false

    
    this.handleShow()
    $('#savedata').click(()=>{
      this.setState({
        showModal:false
      })
      if(operateurVerif){
        fetch('https://integralhub.net:3002/setData/'+CCP+'/'+CONSO_an+'/'+Consommation_théorique+'/'+Contrat+'/'+ECO_Ener+'/'+HFL+'/'+EXTENSION+'/'+NPL+'/'+NPLTOT+'/'+NPL_AN+'/'+NPL_REM+'/'+NPLnv+'/'+P+'/'+Panciens+'/'+Pnouveaux+'/'+Ptotale+'/'+REALISATION+'/'+ZONE+'/'+operateur).then((response)=>{
          window.location.reload();
        })
      }else{
        alert("Vous n'êtes pas autoriser à cette modification")
      }
      
    })
    

    })

   
  }

}

export default Form;