import React,{Component} from 'react'
import './SyntheseFinal.css';
import {withRouter} from 'react-router-dom'
import Firebase from 'firebase'
import {Container,Row,Col,Button,InputGroup,FormControl,Form} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery';
import LogoHAA from './Logo-HAA.png'
import LogoInit from './hadirate2.jpg'
import PLinit from './plinit.jpeg'
import Pinit from './Pinit.jpeg'
import Panne from './panne.jpeg'
import next from './right-arrow.svg'
import taux from './taux.jpeg'
import filter from './filter.svg'
import home from './home.svg'
class SyntheseFinal extends Component{
  constructor(props){
    super(props)
    this.state = {
      Data:[],
      view:{}
    }
  }
  

   componentWillMount(){
     
   }

   componentDidMount(){
    $('.SyntheseFinalVue').hide()

    fetch('https://integralhub.net:3002/getSyntheseZone').then((data)=>{
      let params = this.props.match.params.zone
      let zones = []
      data.json().then((dataJson)=>{
         dataJson.recordset.forEach(item => {
           zones.push(item)
         })
         let data = zones.filter(e=>{return e.id == params}) 
        
         this.setState({
          Data:data
        })
        })

        $('.SyntheseFinalVue').show()

      })
      
      
   }

   render(){
    let params = this.props.match.params.zone
    
     console.log(this.state.Data[0])
    return(
      
      <div className='SyntheseFinal'>
        <img className="HAAinit" src={LogoHAA} width="160" height="150"/>
        <img className="Fig2" src={LogoInit} width="170" height="170"/>
       <a href={"/EP_Marrakech/Dashboard/SyntheseInit/"+params}> <img src={next} className="nextFinal" width="100" height="50" title="Etat initial"/></a>
       <a href={"/EP_Marrakech/Dashboard/Filter"}> <img src={filter} className="filterFinal" width="100" height="50" title="Autre zone"/></a>
       <a href={"/EP_Marrakech/Dashboard/analyse"}> <img src={home} className="homeFinal1" width="100" height="50" title="Acceuil"/></a>
        <p className="InitTitle">Etat actuel</p>
        <p className="ZoneTitle">{this.state.Data.map(e=>(e.ZONE))}</p>
        <div className="SyntheseFinalVue">
        <div className="ConsoFinal">
        <img style={{position:'absolute',bottom:'80px',right:'45px'}} src={taux}  width="70" height="70"/>
        <div style={{textAlign:'center',position:'relative',top:'50%',left:'1%'}}>
            <span style={{color:'#EB8100',fontWeight:'bold'}}>Taux de réalisation</span>
            <br/>
            <span style={{fontWeight:'bold'}}>
              {this.state.Data.map(e=>(
               parseFloat(e.REALISATION * 100).toPrecision(2)
              ))} %</span> 
        </div>
        </div>
        <div className="PLfinal">
        <img style={{position:'absolute',bottom:'90px',right:'80px'}} src={PLinit}  width="70" height="70"/>
        <div style={{textAlign:'center',position:'relative',top:'40%',left:'5%'}}>
            
            <span style={{fontWeight:'bold'}}>
              {this.state.Data.map(e=>(
              e.POINT_REMPL + e.POINT_RESTANT + e.EXTENTION + e.HFL
              ))}</span><br/>
            <span style={{color:'#EB8100',fontWeight:'bold'}}>Nombre de points lumineux</span>
        </div>
        </div>
        <div className="Pannefinal">
        <img style={{position:'absolute',bottom:'80px',right:'45px'}} src={Panne}  width="70" height="70"/>
        <div style={{textAlign:'center',position:'relative',top:'50%',left:'1%'}}>
            <span style={{color:'#EB8100',fontWeight:'bold'}}>Taux de panne</span>
            <br/>
            <span style={{fontWeight:'bold'}}>
              0 %
              </span>
        </div>
        </div>
        <div className="Pfinal">
        <img style={{position:'absolute',bottom:'100px',right:'65px'}} src={Pinit}  width="70" height="70"/>
        <div style={{textAlign:'center',position:'relative',top:'50%',left:'1%'}}>
            <span style={{color:'#EB8100',fontWeight:'bold'}}>Economie d'énergie</span>
            <br/>
            <span style={{fontWeight:'bold'}}>
              {this.state.Data.map(e=>(
               parseFloat(e.ECONO_ACTU * 100).toPrecision(2)
              ))} %</span>
        </div>
        </div>
        <div className="autres">
          <div className="autres1">
            <div style={{width:'60px',height:'60px',borderRadius:'50%',backgroundColor:"white",border:"3px solid #EB8100",zIndex:2}}></div>
            <div style={{width:'80%',height:'50px',backgroundColor:"#EB8100",position:"relative",right:"30px",top:"5px",boxShadow:""}}><p style={{marginLeft:'35px',marginTop:'12px',fontWeight:'bold'}}>Nombre de points remplacés: <span style={{color:'white',fontWeight:'bold'}}>{this.state.Data.map(e=>(
               e.POINT_REMPL
              ))}</span></p></div>
          </div>
          <div className="autres2">
            <div style={{width:'60px',height:'60px',borderRadius:'50%',backgroundColor:"white",border:"3px solid #EB8100",zIndex:2}}></div>
            <div style={{width:'72%',height:'50px',backgroundColor:"#EB8100",position:"relative",right:"30px",top:"5px",boxShadow:""}}><p style={{marginLeft:'35px',marginTop:'12px',fontWeight:'bold'}}>Nombre de points restant: <span style={{color:'white',fontWeight:'bold'}}>{this.state.Data.map(e=>(
               e.POINT_RESTANT
              ))}</span></p></div>
          </div>
          <div className="autres3">
            <div style={{width:'60px',height:'60px',borderRadius:'50%',backgroundColor:"white",border:"3px solid #EB8100",zIndex:2}}></div>
            <div style={{width:'70%',height:'50px',backgroundColor:"#EB8100",position:"relative",right:"30px",top:"5px",boxShadow:""}}><p style={{marginLeft:'35px',marginTop:'12px',fontWeight:'bold'}}>HFL: <span style={{color:'white',fontWeight:'bold'}}>{this.state.Data.map(e=>(
               e.HFL
              ))}</span></p></div>
          </div>
          <div className="autres4">
            <div style={{width:'60px',height:'60px',borderRadius:'50%',backgroundColor:"white",border:"3px solid #EB8100",zIndex:2}}></div>
            <div style={{width:'74%',height:'50px',backgroundColor:"#EB8100",position:"relative",right:"30px",top:"5px",boxShadow:""}}><p style={{marginLeft:'35px',marginTop:'12px',fontWeight:'bold'}}>Puissance totale: <span style={{color:'white',fontWeight:'bold'}}>{this.state.Data.map(e=>(
               parseFloat(e.Puiss_nouveau + e.Puiss_ancien).toPrecision(3)
              ))} kW</span></p></div>
          </div>
          <div className="autres5">
            <div style={{width:'60px',height:'60px',borderRadius:'50%',backgroundColor:"white",border:"3px solid #EB8100",zIndex:2}}></div>
            <div style={{width:'81%',height:'50px',backgroundColor:"#EB8100",position:"relative",right:"30px",top:"5px",boxShadow:""}}><p style={{marginLeft:'35px',marginTop:'12px',fontWeight:'bold'}}>Consommation énergetique: <span style={{color:'white',fontWeight:'bold'}}>{this.state.Data.map(e=>(
               parseFloat(e.CONSO_ACTU).toPrecision(2)
              ))} GWh</span></p></div>
          </div>
        </div>
        </div>
      </div>
      
    )
  }
}

export default withRouter(SyntheseFinal);