import React,{Component} from 'react'
import './AcceuilFiltre.css';
import Firebase from 'firebase'
import {Container,Row,Col,Button,InputGroup,FormControl,Form} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery';
import logoHAA from './Logo-HAA.png'
import Back from './right-arrow.svg'
class AcceuilFiltre extends Component{
  constructor(props){
    super(props)
    this.state = {
      zone:[]
    }
  }
  

   componentWillMount(){
     
   }

   componentDidMount(){

    $('.filtreDiv').hide()
    fetch('https://integralhub.net:3002/getSyntheseZone').then((data)=>{
  
      let zones = []
      data.json().then((dataJson)=>{
         dataJson.recordset.forEach(item => {
           zones.push(item)
         })
         this.setState({
          zone:zones
        })
        })

        $('.filtreDiv').show()

        

      })
   }

   render(){
     let Zones = this.state.zone
    return(
      
      <div className='AcceuilFiltre'>
        <p className="infoText">Veuillez choisir la zone souhaitée</p>
        <img className="FiltreHAA" src={logoHAA} width="160" height="150"/>
       <a href="/EP_Marrakech/Dashboard"><img className="FiltreBack" src={Back} width="100" height="50"/></a> 
       <div className="filtreDiv">
       <Form.Group>
                  <Form.Label className="FiltreLable" style={{fontWeight:'bold'}}>Donnée de synthèse</Form.Label>
                    <Form.Control as="select" className="selectChange" onChange={()=>{
                      let zone = $('#SyntheZone').val()
                      let params = Zones.filter(e=>{return e.ZONE == zone})
                     
                      window.location.href = "/EP_Marrakech/Dashboard/SyntheseInit/"+params[0].id
                    }} id="SyntheZone">
                      <option value=""></option>
                      {
                        Zones.map(e =>(
                          <option value={e.ZONE}>{e.ZONE}</option>
                        ))
                      }
                    </Form.Control>
        </Form.Group>
        <Form.Group>
                  <Form.Label className="FiltreLable" style={{fontWeight:'bold'}}>Consommation énergétique</Form.Label>
                    <Form.Control as="select" className="selectChange" onChange={()=>{
                      let zone = $('#ConsoZone').val()
                      let params = Zones.filter(e=>{return e.ZONE == zone})
                     
                      window.location.href = "/EP_Marrakech/Dashboard/Consommation/"+params[0].id
                    }} id="ConsoZone">
                      <option value=""></option>
                      {
                        Zones.map(e =>(
                          <option value={e.ZONE}>{e.ZONE}</option>
                        ))
                      }
                    </Form.Control>
        </Form.Group>
       </div>
      </div>
      
    )
  }
}

export default AcceuilFiltre;