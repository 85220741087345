import React,{Component} from 'react'
import './DiagHome.css';
import $ from 'jquery';
import Form from '../OfficeForm/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container,Button } from 'reactstrap';
import { MDBDataTableV5 } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'mdbreact/dist/css/mdb.css'
import logo from "./Logo-prod'air.png"
import logoPl from "./logoPL.png"
import logoCoffret from "./logoCoffret.png"
import logoVoirie from "./logoVoirie.png"
import logoCEP from "./LogoCEP.png"
import offIcon from './off.svg'

class Home extends Component{
  constructor(props){
    super(props)
    this.state = {
      user:'',
      linkPL:{link:'#',class:'noClick'},
      linkCoffret:{link:'#',class:'noClick'},
      linkVoirie:{link:'#',class:'noClick'},
      linkCEP:{link:'#',class:'noClick'}
     }
  }
  componentWillMount(){
    if(sessionStorage.getItem('userVerify') == "Borio5213"){
      
      this.setState({
        user:sessionStorage.getItem('user')
      }) 

    }else{
      window.location.href = '/EP_Marrakech/Diagnostic/auth'
    }
   
      $("title").html("PROD'ECO")
      $(".link").attr('href',logo)
   
  }
  disconnect(){
    sessionStorage.setItem('userVerify','X#ghuurRh');
    window.location.href = '/EP_Marrakech/Diagnostic/auth'
  }

  componentDidMount(){
    let getRole = sessionStorage.getItem('role')
    let getRole2 = sessionStorage.getItem('role2')
    let getRole3 = sessionStorage.getItem('role3')

    if(getRole == 'full'){
      this.setState({
        linkPL:{link:'/EP_Marrakech/Diagnostic/PL',class:''},
      linkCoffret:{link:'/EP_Marrakech/Diagnostic/Coffret',class:''},
      linkVoirie:{link:'/EP_Marrakech/Diagnostic/Voiries',class:''},
      linkCEP:{link:'/EP_Marrakech/Diagnostic/CEP',class:''}
      })
    }

    if(getRole == 'PL' || getRole2 == 'PL' || getRole3 == 'PL'){
      this.setState({
        linkPL:{link:'/EP_Marrakech/Diagnostic/PL',class:''},
      })
    }
    if(getRole == 'coffret' || getRole2 == 'coffret' || getRole3 == 'coffret'){
      this.setState({
      linkCoffret:{link:'/EP_Marrakech/Diagnostic/Coffret',class:''},
      })
    }

    if(getRole == 'voirie' || getRole2 == 'voirie' || getRole3 == 'voirie'){
      this.setState({
      linkVoirie:{link:'/EP_Marrakech/Diagnostic/Voiries',class:''}
      })
    }

    if(getRole == 'cep' || getRole2 == 'cep' || getRole3 == 'cep'){
      this.setState({
      linkCEP:{link:'/EP_Marrakech/Diagnostic/CEP',class:''}
      })
    }
  }

  render(){
    return(
          <div className='Home'>
            <img width="30" height="30" src={offIcon} className="disconnect" onClick={this.disconnect} title="déconnexion"/>
            <img src={logo} className="logoAuthen" width="200" height="200" />
            <Container className="contenue">
              <a href={this.state.linkPL.link} className={this.state.linkPL.class}><img src={logoPl} width="200" height="85"/></a>
              <a href={this.state.linkVoirie.link} className={this.state.linkVoirie.class}><img src={logoVoirie} width="200" height="85"/></a>
              <a href={this.state.linkCEP.link} className={this.state.linkCEP.class}><img src={logoCEP} width="200" height="85"/></a>
              <a href={this.state.linkCoffret.link} className={this.state.linkCoffret.class}><img src={logoCoffret} width="200" height="85"/></a>
            </Container>
            </div>
     )
  }



}

export default Home;