import React,{Component} from 'react'
import './slidebar.css';
import menuIcon from './menu.png'
import infoIcon from './info.png'
import adminIcon from './admin.png'
import logo from './logo.png'
import logoM from './logoMasque.png'
import home from './accueil.svg'
import chart from './statistics.svg'
import $ from 'jquery'

class SlideBar extends Component{
  constructor(props){
    super(props)
  }

  render(){
    let admin
    if(sessionStorage.getItem('admin')=='226'){
      admin = <a href="/EP_Marrakech/Dashboard/Admin"><div className='LinkInfo'><img width='25' height="25" src={adminIcon} title='Accès adminitrateur'/><span>Admin</span></div></a>
    }
    return(
      <div className='SlideBar'>
        <div className='menuTitle'><img width='23' height="25" src={menuIcon} title='menu'/><span></span></div>
        <a href={"/EP_Marrakech/Dashboard"}><div className='LinkInfo'><img width='25' height="25" src={home} title='Acceuil'/><span>Acceuil</span></div></a>
        <a href="/EP_Marrakech/Dashboard/analyse"><div className='LinkInfo'><img width='25' height="25" src={chart} title='Analyses'/><span>Analyses</span></div></a>
        
        

        {
          admin
        }
      </div>
    )
  }

  componentDidMount(){
    let imgMenu = $('.menuTitle img')
    let infoMenu = $('.LinkInfo span')
    let slideBar = $('.SlideBar')
    let EnteteLogo = $(".Entete img")
    let mask = $('.mask')

    EnteteLogo.attr('height','30').attr('width','25')
    imgMenu.click((evnt)=>{
      var clicks = $(this).data('clicks');
      imgMenu.css('transform','rotate(90deg)')
      //imgMenu.css('-webkit-transform','rotate(90deg)')
  if (clicks) {
    slideBar.css('width','40px').css('transition-duration','0.2s')
       infoMenu.css('visibility','hidden')
       imgMenu.css('transform','rotate(0deg)')
       EnteteLogo.attr('src',logoM)
       EnteteLogo.attr('height','30').attr('width','25')
       $('.SlideBar .LinkInfo').css('top','80px')
       $('.menuTitle').css('top','70px')
       $('.filterInit').css('left','40px')
       $('.ZoneTitle').css('left','5%')
    $('.homeInit1').css('left','120px')
    $('.nextFinal').css('left','40px')
    $('.FiltreBack').css('left','40px')
       mask.css('left','53px').css('transition-duration','0.2s')
  } else {
    slideBar.css('width','8%').css('transition-duration','0.2s')
    $('.SlideBar .LinkInfo').css('top','120px')
    $('.menuTitle').css('top','110px')
    $('.filterInit').css('left','8%')
    $('.homeInit1').css('left','14%')
    $('.ZoneTitle').css('left','10%')
    $('.nextFinal').css('left','8%')
    $('.FiltreBack').css('left','8%')
    mask.css('left','120px').css('transition-duration','0.2s')
    setTimeout(()=>{
    EnteteLogo.attr('src',logo)
    EnteteLogo.attr('height','100').attr('width','100')
      infoMenu.css('visibility','visible')
    },200)
  }
  $(this).data("clicks", !clicks);
      
    })
  }
}

export default SlideBar;