import React,{Component} from 'react'
import './signup.css';
import Firebase from 'firebase'
import admin from 'firebase-admin'
import usericon from './user.svg'
import logo from './logo.png'
import {Container,Row,Col,Button,InputGroup,FormControl,Form} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
class Signup extends Component{
  constructor(props){
    super(props)
    this.state = {
      signupConfirme : "",
      login:"",
      pass1:"",
      pass2:"",
      quitte:false,
      name:""
    }
  }
  signUp = ()=>{
    let userlogin = this.state.login;
    let userpass1 = this.state.pass1;
    let userpass2 = this.state.pass2;
    let name = this.state.name
    var firebaseConfig = {
      apiKey: "AIzaSyBjAfuQ9tGHWnS8ZDp7WPinIBT8XXsIhC8",
      authDomain: "marrakechsignin.firebaseapp.com",
      projectId: "marrakechsignin",
      storageBucket: "marrakechsignin.appspot.com",
      messagingSenderId: "217563396934",
      appId: "1:217563396934:web:30bf2140ecd57134838347",
      measurementId: "G-0FHRM38WBE"
    };
    
  
    (!Firebase.apps.length) ? Firebase.initializeApp(firebaseConfig) : Firebase.app();
    //Firebase.initializeApp(firebaseConfig)
    Firebase.analytics()
    if(userpass1 === userpass2){
      Firebase.auth().createUserWithEmailAndPassword(userlogin,userpass1).then((succes)=>{
        succes.user.updateProfile({displayName:name}).then((e)=>{
          var database = Firebase.database().ref('marrakechsignin-default-rtdb')
          var newPostref = database.push()
          newPostref.set({
            mail:userlogin,
            activate:false,
            name:succes.user.displayName,
            date:new Date().toString()
          })
          console.log(new Date().toString())
        })
        
        

        this.setState({
                      signupConfirme:"Demande effectuée.",
                      pass1:"",
                      pass2:"",
                      quitte:true,
                      login:"",
                      name:""
                        })
        

        // admin.initializeApp({
        //   credential:admin.credential.applicationDefault,
        //   databaseURL:"https://marrakechsignin.firebaseio.com"
        // })
        // admin.auth().updateUser(succes.user.uid,{disabled:true})
  
      }).catch((error)=>{
        this.setState({signupConfirme:"Demande échouer, réessayer"})
        console.log('error')
      })
    }else{
      this.setState({signupConfirme:"Erreur de confirmation du mot de passe"})
    }
    
    console.log(userlogin,userpass1,userpass2)
   }

   handleLoginChange = (e)=>{
    this.setState({login: e.target.value});
    } 
    handleNameChange = (e)=>{
      this.setState({name: e.target.value});
      } 
   handlePasswordChange1 = (e)=>{
    this.setState({pass1: e.target.value});
   }
   handlePasswordChange2 = (e)=>{
    this.setState({pass2: e.target.value});
   }

   render(){
    let {signupConfirme,login,pass1,pass2,quitte,name} = this.state
    return(
      <div className='authen'>
        <img src={logo} className="logoA" width="200" height="200" />
       <div className="containt">    
 <Row className="header">
      <img width="50" height="50" src={usericon}></img>
  </Row>
  <Row className="login">
       <Form>
       <Form.Group controlId="formLogin">
        <Form.Label>Nom & prénom</Form.Label>
        <Form.Control type="text" placeholder="nom et prénom" value={name} onChange={this.handleNameChange} />
       </Form.Group>
        <Form.Group controlId="formLogin">
        <Form.Label>Votre adresse mail</Form.Label>
        <Form.Control type="email" placeholder="login, ex: adresse@domaine.com" value={login} onChange={this.handleLoginChange} required className=""/>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
           <Form.Label>Mot de passe</Form.Label>
           <Form.Control type="password" placeholder="Password, 8 caractères minimum" value={pass1} onChange={this.handlePasswordChange1}/>
           <Form.Label>Confirmation mot de passe</Form.Label>
           <Form.Control type="password" placeholder="Password" value={pass2} onChange={this.handlePasswordChange2}/>
        </Form.Group>
        <Button variant="dark" type="Button" onClick={this.signUp} block>
          Envoyer
       </Button>
       <Form.Text className="text-muted">
           <span>{signupConfirme}</span>
        </Form.Text>
       <span className="quitter"><a href="/EP_Marrakech/Dashboard/auth">{quitte ? "Quitter" : ""}</a></span>
     </Form>
</Row>
       </div>
      </div>
    )
  }
}

export default Signup;