import React,{Component} from 'react'
import './SyntheseInit.css';
import {withRouter} from 'react-router-dom'
import Firebase from 'firebase'
import {Container,Row,Col,Button,InputGroup,FormControl,Form} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery';
import LogoHAA from './Logo-HAA.png'
import LogoInit from './hadirate1.jpg'
import PLinit from './plinit.jpeg'
import Pinit from './on-off.png'
import Panne from './panne.jpeg'
import next from './right-arrow.svg'
import filter from './filter.svg'
import home from './home.svg'
class SyntheseInit extends Component{
  constructor(props){
    super(props)
    this.state = {
      Data:[],
      view:{}
    }
  }
  

   componentWillMount(){
     
   }

   componentDidMount(){
     $('.SyntheseInitVue').hide()
    fetch('https://integralhub.net:3002/getSyntheseZone').then((data)=>{
      let params = this.props.match.params.zone
      let zones = []
      data.json().then((dataJson)=>{
         dataJson.recordset.forEach(item => {
           zones.push(item)
         })
         let data = zones.filter(e=>{return e.id == params}) 
        
         this.setState({
          Data:data
        })
        })

        $('.SyntheseInitVue').show()

        

      })

      
   }

   render(){
    let params = this.props.match.params.zone
    let puissanceInit = [{id:1,P:961},{id:2,P:831},{id:3,P:495},{id:4,P:705},{id:5,P:261},{id:6,P:519},{id:7,P:574},{id:8,P:751},{id:9,P:360},{id:10,P:542},{id:11,P:1057},{id:12,P:696},{id:13,P:507},{id:14,P:482},{id:15,P:666},{id:16,P:597}]
    let PuissStat = puissanceInit.filter(e=>{return e.id == params})
    console.log(PuissStat[0].P)
    return(
      
      <div className='SyntheseInit'>
        <img className="HAAinit" src={LogoHAA} width="160" height="150"/>
        <img className="Fig1" src={LogoInit} width="170" height="170"/>
       <a href={"/EP_Marrakech/Dashboard/SyntheseFinal/"+this.state.Data.map(e=>(e.id))}> <img src={next} className="nextInit" width="100" height="50" title="Etat Actuel"/></a>
       <a href={"/EP_Marrakech/Dashboard/Filter"}> <img src={filter} className="filterInit" width="100" height="50" title="Autre zone"/></a>
       <a href={"/EP_Marrakech/Dashboard/analyse"}> <img src={home} className="homeInit1" width="20" height="20" title="Acceuil"/></a>
        <p className="InitTitle">Etat initial</p>
        <p className="ZoneTitle">{this.state.Data.map(e=>(
              e.ZONE
              ))}</p>
        <div className="SyntheseInitVue">
        <div className="ConsoInit">
        <div style={{textAlign:'center',position:'relative',top:'30%'}}>
            <span style={{color:'#EB8100',fontWeight:'bold'}}>Consommation énergétique</span>
            <br/>
            <span style={{fontWeight:'bold'}}>
              {this.state.Data.map(e=>(
               parseFloat(e.CONSO_AVANT_RENOUV).toFixed(2)
              ))} GWh</span>
        </div>
        </div>
        <div className="PLinit">
        <img style={{position:'absolute',bottom:'80px',right:'54px'}} src={PLinit}  width="70" height="70"/>
        <div style={{textAlign:'center',position:'relative',top:'40%',left:'5%'}}>
            
            <span style={{fontWeight:'bold'}}>
              {this.state.Data.map(e=>(
               e.POINT_ORIGIN
              ))}</span><br/>
            <span style={{color:'#EB8100',fontWeight:'bold'}}>Nombre de points lumineux</span>
        </div>
        </div>
        <div className="PanneInit">
        <img style={{position:'absolute',bottom:'80px',right:'45px'}} src={Panne}  width="70" height="70"/>
        <div style={{textAlign:'center',position:'relative',top:'50%',left:'1%'}}>
            <span style={{color:'#EB8100',fontWeight:'bold'}}>Taux de panne</span>
            <br/>
            <span style={{fontWeight:'bold'}}>
              0 %
              </span>
        </div>
        </div>
        <div className="Pinit">
        <img style={{position:'absolute',bottom:'95px',right:'60px'}} src={Pinit}  width="40" height="40"/>
        <div style={{textAlign:'center',position:'relative',top:'50%',left:'1%'}}>
            <span style={{color:'#EB8100',fontWeight:'bold'}}>Puissance</span>
            <br/>
            <span style={{fontWeight:'bold'}}>
              {PuissStat[0].P} kW</span>
        </div>
        </div>
        </div>
      </div>
      
    )
  }
}

export default withRouter(SyntheseInit);