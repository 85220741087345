import React,{Component} from 'react'
import './Authen.css';
import Firebase from 'firebase'
import usericon from './user.svg'
import logo from './logo.png'
import {Container,Row,Col,Button,InputGroup,FormControl,Form} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
class Authen extends Component{
  constructor(props){
    super(props)
    this.state = {
      loginError : "",
      login:"",
      pass:""
    }
  }
  signIn = ()=>{
    let userlogin = this.state.login;
    let userpass = this.state.pass;

    var firebaseConfig = {
      apiKey: "AIzaSyBjAfuQ9tGHWnS8ZDp7WPinIBT8XXsIhC8",
      authDomain: "marrakechsignin.firebaseapp.com",
      projectId: "marrakechsignin",
      storageBucket: "marrakechsignin.appspot.com",
      messagingSenderId: "217563396934",
      appId: "1:217563396934:web:30bf2140ecd57134838347",
      measurementId: "G-0FHRM38WBE"
    };
    
  
    (!Firebase.apps.length) ? Firebase.initializeApp(firebaseConfig) : Firebase.app();
    //Firebase.initializeApp(firebaseConfig)
    Firebase.analytics()

    Firebase.auth().signInWithEmailAndPassword(userlogin,userpass).then((succes)=>{
      
      var rootRef = Firebase.database().ref()
      var data = rootRef.child('marrakechsignin-default-rtdb').orderByChild('mail').equalTo(userlogin)
      data.on('child_added',(snap)=>{
        console.log(snap.child('activate').val())
        if(snap.child('activate').val() === true){
          sessionStorage.setItem('user',succes.user.displayName);
          sessionStorage.setItem('userVerify','Borio5213');
          sessionStorage.setItem('operateur',snap.child('mail').val());
          if(succes.user.displayName.toLowerCase() == 'administrateur'){
            sessionStorage.setItem('admin','226');
          }else{
            sessionStorage.setItem('admin','popiropopo');
          }
          window.location.href = '/EP_Marrakech/Dashboard'
        }else{
          this.setState({loginError:"Profil non autorisé, contacter l'administrateur"})
        }
      })
      //

    }).catch((error)=>{
      this.setState({loginError:"Veuillez entrée des indentifiants valides"})
      console.log('error')
    })
    console.log(userlogin,userpass)
   }

   handleLoginChange = (e)=>{
    this.setState({login: e.target.value});
    } 
   handlePasswordChange = (e)=>{
    this.setState({pass: e.target.value});
   }

   render(){
    let {loginError,login,pass} = this.state
    return(
      <div className='authen'>
        <img src={logo} className="logoAuthen1" width="200" height="200" />
       <div className="containt">    
 <Row className="header">
      <img width="50" height="50" src={usericon}></img>
  </Row>
  <Row className="login">
       <Form>
        <Form.Group controlId="formLogin">
        <Form.Label>Identifiant</Form.Label>
        <Form.Control type="text" placeholder="adresse mail" value={login} onChange={this.handleLoginChange} />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
           <Form.Label>Mot de passe</Form.Label>
           <Form.Control type="password" placeholder="Password" value={pass} onChange={this.handlePasswordChange}/>
        </Form.Group>
        <Button variant="dark" type="Button" onClick={this.signIn} block>
          Se connecter
       </Button>
       <Form.Text className="error">
           <span>{loginError}</span>
        </Form.Text>
       <span><a href='/EP_Marrakech/Dashboard/signup'>Demande d'inscription</a></span>
     </Form>
</Row>
       </div>
      </div>
    )
  }
}

export default Authen;