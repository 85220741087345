import React,{Component} from 'react'
import './consommation.css';
import Firebase from 'firebase'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {withRouter} from 'react-router-dom'
import filter from './filter.svg'
import home from './home.svg'
import LogoHAA from './Logo-HAA.png'

import {Container,Row,Col,Button,InputGroup,FormControl,Form} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery';

require("highcharts/modules/exporting")(Highcharts);

class ConsoHome extends Component{
  constructor(props){
    super(props)
    this.state = {
     consoZone:[],
     Data:[],
     filename:''
    
    }
  }
  

   componentWillMount(){
     
   }

   componentDidMount(){
     //$('.charts').hide()
    fetch('https://integralhub.net:3002/getConsoZone').then((data)=>{
        
        let rows = []
        data.json().then((dataJson)=>{
           dataJson.recordset.forEach(item => {
             rows.push(item)
           })
           let zone = rows.filter(e=>{return e.id == this.props.match.params.zone})
           this.setState({
              consoZone:rows,
              Data:zone,
              filename:zone[0].ZONE
            })
           
          })
          //$('.charts').show()
          
          
        })
   }

   render(){
     console.log(this.state.consoZone)
     let data = []
    this.state.Data.map(e=>{
      data.push(e.["2017"]/1000000,e.["2018"]/1000000,e.["2019"]/1000000,e.["2020"]/1000000)
    })
    console.log(data)
     const options = {
      chart: {
        renderTo: 'container',
        type: 'line',
        height: 340,
        backgroundColor:'#ffffff'
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      headerFormat: ''
    },
    exporting: {
      enabled: true,
      filename:'consommation_Zone_'+this.state.filename.toUpperCase()
    },
    colors: ['#EB8100'],
    credits: {
      enabled: false
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
  },
      title: {
        text: 'Consommation énergétique annuelle'
      },
      xAxis: {
        categories: ['2017', '2018', '2019','2020'],
      },
      yAxis: {
        title: {
            text: 'CONSOMMATION (GWh)'
        }
      },
      series: [
        { name:'Consommation (GWh)',
        data: data }
      ],

    }
    return(
    
      <div className='consoHome'>
        <img className="HAAinit" src={LogoHAA} width="160" height="150"/>
        <a href={"/EP_Marrakech/Dashboard/Filter"}> <img src={filter} className="filterFinal" width="100" height="50" title="Autre zone"/></a>
       <a href={"/EP_Marrakech/Dashboard/analyse"}> <img src={home} className="homeFinal1" width="100" height="50" title="Acceuil"/></a>
       {/* <p className="ConsoTitle">Consommation annuelle</p> */}
        <p className="ZoneTitle">{this.state.Data.map(e=>(e.ZONE.toUpperCase()))}</p>
        <div className="charts">
        <HighchartsReact
    highcharts={Highcharts}
    options={options}
  />
        </div>
        
      </div>
      
    )
  }
}

export default withRouter(ConsoHome);