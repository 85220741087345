import React,{Component} from 'react'
import './Voirie.css';
import $ from 'jquery';
import Forms from '../OfficeForm/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'reactstrap';
import { MDBDataTableV5 } from 'mdbreact';
import {Form,Button,Modal,Spinner} from 'react-bootstrap'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'mdbreact/dist/css/mdb.css'
import logoutIcon from './logout.svg'
import logo1 from "./Logo-prod'air.png"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class Voiries extends Component{
  constructor(props){
    super(props)
    this.state = {
      Alldata:[],
      getdepart:[],
      pictureliste:[],
      coffretPicture:[],
      spinner:false,
      coffrets:[],
      Tabledata:{
        columns:[],
        rows:[]
      }
   
    }
  }
  componentWillMount(){
    if(sessionStorage.getItem('userVerify') == "Borio5213"  && (sessionStorage.getItem('role') == "voirie" || sessionStorage.getItem('role') == "full" || sessionStorage.getItem('role2') == "voirie" || sessionStorage.getItem('role3') == "voirie")){
       

    }else{
      window.location.href = '/EP_Marrakech/Diagnostic/auth'
    }
    
      $("title").html("PROD'ECO")
      $(".link").attr('href',logo1)
   
  }
  componentDidMount(){
    fetch("https://integralhub.net:3005/getPicturesAll").then((data)=>{
      let row = []
    data.json().then((dataJson)=>{
       dataJson.forEach(item => {
         row.push(item)
       })

       this.setState({
        Alldata:row
        })
      }) 
    })
    fetch("https://integralhub.net:3005/getAllCoffret").then((data)=>{
      let coffret = []
    data.json().then((dataJson)=>{
       dataJson.forEach(item => {
         coffret.push(item)
       })

       this.setState({
        coffrets:coffret
        })
      }) 
    })

    $('.CoffretInfo').hide()

   
  }

  downloadpictures = ()=>{
    var picturelenght = this.state.coffretPicture.length 
    var body = document.body
  
    if(picturelenght > 0){
      var urls = []
      this.state.coffretPicture.forEach((e)=>{
        urls.push(e)
        // console.log(e.link)
        //   let a = document.createElement('a')
        //   a.setAttribute('href', e.link);
        //   a.setAttribute('download', e.name);
        //   a.setAttribute('target', '_blank');
        //   a.click();    
      });
        
  for (var i = 0; i < urls.length; i++) { 
    setTimeout(function (path) { window.location = path; }, 1000 + i * 500, urls[i]);
  }        

    }else{
      alert("Veillez sélectionner un coffret pour télécharger les images correspondantes")
    }
    
    
  }

  render(){
  
    return(
          <div className='Voiries'>
            <div className="Htitle">DIAGNOSTIQUE VOIRIE ECLAIRAGE PUBLIC MARRAKECH</div>
            <div class="PLCompteur">Nombre de Coffret: {this.state.coffrets.length}</div>
            <a href="/EP_Marrakech/Diagnostic"><img src={logoutIcon} className='logout2' width="30" height="30" title="TABLEAU DE BORD"/></a> 
            <div className="filtre1">
               <table>
                 <tr>
                   <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label className="label">N° Coffret</Form.Label>
    <Form.Control as="select"  style={{height:350}} multiple id="valCoff1" onChange={()=>{
      let ncoffret = $('#valCoff1').val()
      let {Alldata} = this.state
      let departs = Alldata.filter(e=> {return e.ncoffret == ncoffret})
      let departNum = []
      let coffretpict = []
      
      departs.map(e=>{
        if($.inArray(e.ndepart, departNum) === -1) departNum.push(e.ndepart);
        coffretpict.push("https://integralhub.net:3007/files/"+e.picturename)
      })
      this.setState({
        getdepart:departNum.sort(),
        coffretPicture:coffretpict,
        Tabledata:{
          columns:[{
            label: 'N° Départ',
            field: 'ndepart',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Images',
            field: 'picturename',
            width: 100,
          },
          {
            label: 'Date de prise',
            field: 'date',
            width: 100,
          },
          {
            label: 'Opérateur',
            field: 'operateur',
            width: 100,
          },
        ],
        rows:departs
        }
      })
      $('.CoffretInfo').show()
      $('.pictureDiapo').hide()
       
      $('.selectChange').val("")
       
     
      
    }}>
      <option value=""></option>
      {
        this.state.coffrets.map((e)=>(
          <option value={e.ncoffret}>{e.ncoffret}</option>
        ))
      }
    </Form.Control>
  </Form.Group></tr>
  
  <tr>
                 <Button variant="dark" type="button" className="btn-block downlBtn" onClick={this.downloadpictures}>
              telecharger images coffret
                 </Button>
                 </tr>
                   <tr>
                   <Form.Group>
                  <Form.Label className="label">Images départ</Form.Label>
                    <Form.Control as="select" className="selectChange" onChange={()=>{
                      let ncoffret = $('#valCoff1').val()
                      let ndepart = $('#Opt1').val() 
                      let {Alldata} = this.state
                      $('.CoffretInfo').hide()
                      $('.pictureDiapo').show()
                      let picture = Alldata.filter(e=> {return e.ncoffret == ncoffret && e.ndepart == ndepart})
                      let pictureName = []
                      this.setState({
                        pictureliste:pictureName,
                        spinner:true
                      })
                      picture.sort().map((e)=>{
                        pictureName.push({link:"https://integralhub.net:3007/files/"+e.picturename,name:e.picturename})
                      })

                      
                      setTimeout(()=>{
                        this.setState({
                          pictureliste:pictureName,
                          spinner:false
                        })
                      },1000)
                      
                    }} id="Opt1">
                      <option value="" selected></option>
                      {
                        this.state.getdepart.map(e =>(
                          <option value={e}>{e}</option>
                        ))
                      }
                    
                    </Form.Control>
               </Form.Group>
                     
                 </tr>
                 <tr>
                 <Button variant="dark" type="button" className="btn-block downlBtn" onClick={()=>{ window.location.reload();}}>
              Reset
                 </Button>
                 </tr>
               </table>

             </div>
             <div className="pictureDiapo">
             {
             this.state.spinner == true ? <Spinner animation="border" role="status" className="spinner1"><span className="sr-only">Loading...</span></Spinner> : <Carousel>
               
               { this.state.pictureliste.map((e)=>(
                  <div >
                  <img height="530" src={e.link} />
                  <p className="legend">{e.name.split('.')[0]}</p>
              </div>
                 ))
                 }      
            </Carousel>
            }
             </div>
             <div className="CoffretInfo">
             <MDBDataTableV5  hover entriesOptions={[8,20]} entries={8} pagesAmount={4} data={this.state.Tabledata}  striped
      bordered
      small
      searchTop
      searchBottom={false}
      />
             </div>
          </div>   
     )
  }



}

export default Voiries;