import React,{Component} from 'react'
import './office.css';
import $ from 'jquery';
import Form from '../OfficeForm/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'reactstrap';
import { MDBDataTableV5,M } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'mdbreact/dist/css/mdb.css'
import logoutIcon from './logout.svg'

class Office extends Component{
  constructor(props){
    super(props)
    this.state = {
      data:{
        columns:[],
        rows:[]
      },
      pushElement:[{
        CCP: "",
        CONSO_an:"",
        Consommation_théorique: "",
        Contrat: "",
        ECO_Ener: "",
        EXTENSION: "",
        NPL: "",
        NPLTOT: "",
        NPL_AN: "",
        NPL_REM: "",
        NPLnv: "",
        P: "",
        Panciens: "",
        Pnouveaux: "",
        Ptotale: "",
        REALISATION: "",
        ZONE: ""
      }],
      dataTotal:0,
      searchcount:0,
     
    }
  }
  async componentDidMount(){
    if(sessionStorage.getItem('admin') != "226"){
      window.location.href = '/EP_Marrakech/Dashboard/auth' 

    }
   
    
    fetch('https://integralhub.net:3002/getData').then((data)=>{
      let columns = [
        {
          label: 'N° CONTRAT',
          field: 'Contrat',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'CCP',
          field: 'CCP',
          width: 150,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'CCP',
          },
        },
        {
          label: 'ZONE',
          field: 'ZONE',
          width: 100,
        },
        {
          label: 'NPL ORIGINE',
          field: 'NPL',
          width: 100,
        },
        {
          label: 'P.TOTALE ORIGINE(kW)',
          field: 'P',
          width: 100,
        },
        {
          label: 'CONSO.THRQ ORIGINE(kWh)',
          field: 'Consommation_théorique',
          width: 200,
        },
        {
          label: 'NPL NOUVEAU',
          field: 'NPLnv',
          width: 100,
        },
        {
          label: 'NPL ANCIEN',
          field: 'NPL_AN',
          width: 100,
        },
        {
          label: 'NPL TOTAL',
          field: 'NPLTOT',
          width: 100,
        },
        {
          label: 'NPL REMPLACE',
          field: 'NPL_REM',
          width: 100,
        },
        {
          label: 'HFL',
          field: 'HFL',
          width: 100,
        },
        {
          label: 'EXTENTION HAA',
          field: 'EXTENSION',
          width: 100,
        },
        {
          label: 'TAUX DE REALISATION',
          field: 'REALISATION',
          width: 100,
        },
        {
          label: 'PTOTALE(kW)',
          field: 'Ptotale',
          width: 100,
        },
        {
          label: 'PNOUVEAU.LUMINAIRE (kW)',
          field: 'Pnouveaux',
          width: 100,
        },
        {
          label: 'PANCIEN.LUMINAIRE (kW)',
          field: 'Panciens',
          width: 100,
        },
        {
          label: 'CONSOMMATION ANNUELLE (kWh)',
          field: 'CONSO_an',
          width: 270,
        },
        {
          label: "ECONOMIE D'ENERGIE",
          field: 'ECO_Ener',
          width: 150,
        },
      ]
      let rows = []
      data.json().then((dataJson)=>{
         dataJson.recordset.forEach(item => {
           rows.push(item)
         })
         this.setState({
          data:{
            columns:columns,
            rows:rows
          },
          dataTotal:rows.length,
          searchcount:rows.length
         })
         
        })
      })

    $('.Table tbody').click((e)=>{
      let element = e.target.parentNode.firstChild.textContent
      fetch('https://integralhub.net:3002/getdataElement/'+element).then((data)=>{
      data.json().then((dataJson)=>{
         this.setState({
          pushElement:dataJson.recordset
         })
         
        })
      })
    })

    
  }

  render(){
    return(
          <div className='Office'>
            <p className="compteData">Nombre de coffret: {this.state.searchcount} / {this.state.dataTotal}</p>
            <div className='Table'>
            <MDBDataTableV5 className="dataTable" hover entriesOptions={[7, 50, 100]} entries={7} pagesAmount={4} data={this.state.data} onSearch={(e)=>{
              setTimeout(() => {
                const infocount = $('.mdb-datatable-info').children().text().split(' ')[5]
                this.setState({
                  searchcount:infocount
                })
              }, 500);
               

                if(e == ''){
                  this.setState({
                    searchcount:this.state.dataTotal
                  })
                }
            }} />
            </div>
            <div className='formulaire'>
            <Form dataElement={this.state.pushElement}/>
            </div>
           <a href="/EP_Marrakech/Dashboard"><img src={logoutIcon} className='logout' width="30" height="30" title="TABLEAU DE BORD"/></a> 
          </div>   
     )
  }



}

export default Office;