import React,{useRef} from 'react';
import { MapContainer, TileLayer, Marker, Popup,useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { Icon,Point } from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


function MyComponent(props) {
    const map = useMap()
    map.setView(props.center,props.zoom) 
    return null
  }

function Map(props){
    //const map = useMap();
    
    if(props.load){
        // const { current = {} } = mapRef; 
        // const {leafletElement : map } = current;
        // map.setView(props.center,props.zoom)
        //map.setView(props.center,props.zoom)
    }
   
    let DefaultIcon = new Icon({
      iconUrl: icon,
      //shadowUrl: iconShadow,
       iconSize:     [20, 30],
       iconAnchor:   [10,10],
    });
 
  return (
<MapContainer className="map" id="map" center={props.center} zoom={props.zoom} scrollWheelZoom={false}>
      <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={props.center} icon={DefaultIcon}></Marker>
      <MyComponent center={props.center} zoom={props.zoom}/>
                            </MapContainer>
  )
}
 
export default Map;