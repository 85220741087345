import React,{Component} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Header from './component/Header/header'
import SlideBar from './component/SlideBar/slidebar'
import Report from './component/Report/report'
import Footer from './component/Footer/footer'
import Footer1 from './component/Footer1/footer1'
import Authen from './component/Authentification/Authen'
import AuthenDiag from './component/AuthenDiag/AuthenDiag'
import Signup from './component/signup/signup'
import Office from './component/Backoffice/office'
import DiagHome from './component/DiagnHome/DiagHome'
import DiagCoffret from './component/DiagnCoffret/coffret'
import DiagPL from './component/DiagnPL/PL'
import DiagVoiries from './component/DiagnVoirie/Voirie'
import AcceuilHome from './component/Acceuil/AcceuilHome/AcceuilHome'
import AcceuilFiltre from './component/Acceuil/AcceuilFiltre/AcceuilFiltre'
import AcceuilInit from './component/Acceuil/SyntheseInit/SyntheseInit'
import AcceuilFinal from './component/Acceuil/SyntheseFinal/SyntheseFinal'
import Consommation from './component/Acceuil/consommation/consommation'
import DiagCEP from './component/DiagnCEP/CEP'

class App extends Component{
  constructor(props){
    super(props)
  }
 
  render(){
    return(
      <Router>
        <Switch>
        <Route exact path="/EP_Marrakech/Dashboard/signup">
                <Signup/>
          </Route>
          <Route exact path="/EP_Marrakech/Dashboard/auth">
                <Authen/>
          </Route>
          <Route exact path="/EP_Marrakech/Diagnostic/auth">
                <AuthenDiag/>
          </Route>
          <Route exact path="/EP_Marrakech/Dashboard/Admin">
                <div className='App'>
                <Header/>
                <Office/>
                <Footer/>
                </div>
          </Route>
          <Route exact path="/EP_Marrakech/Diagnostic">
                <div className='App'>
               
                <DiagHome/>
                <Footer1/>
                </div>
          </Route>
          <Route exact path="/EP_Marrakech/Diagnostic/PL">
                <div className='App'>
                
                <DiagPL/>
                <Footer1/>
                </div>
          </Route>
          <Route exact path="/EP_Marrakech/Diagnostic/Coffret">
                <div className='App'>
               
                <DiagCoffret/>
                <Footer1/>
                </div>
          </Route>
          <Route exact path="/EP_Marrakech/Diagnostic/Voiries">
                <div className='App'>
                
                <DiagVoiries/>
                <Footer1/>
                </div>
          </Route>
          <Route exact path="/EP_Marrakech/Diagnostic/CEP">
                <div className='App'>
                <DiagCEP/>
                <Footer1/>
                </div>
          </Route>
        <Route exact path="/EP_Marrakech/Dashboard/analyse">
      <div className='App'>
       <Header/>
       <div className="Section">
       <SlideBar/>
       <AcceuilHome/>
       </div>
       <Footer/>

      </div>
      </Route>
      <Route exact path="/EP_Marrakech/Dashboard/Filter">
      <div className='App'>
       <Header/>
       <div className="Section">
       <SlideBar/>
       <AcceuilFiltre/>
       </div>
       <Footer/>

      </div>
      </Route>
      <Route exact path="/EP_Marrakech/Dashboard/SyntheseInit/:zone">
      <div className='App'>
       <Header/>
       <div className="Section">
       <SlideBar/>
       <AcceuilInit/>
       </div>
       <Footer/>

      </div>
      </Route>
      <Route exact path="/EP_Marrakech/Dashboard/SyntheseFinal/:zone">
      <div className='App'>
       <Header/>
       <div className="Section">
       <SlideBar/>
       <AcceuilFinal/>
       </div>
       <Footer/>

      </div>
      </Route>
      <Route exact path="/EP_Marrakech/Dashboard">
      <div className='App'>
       <Header/>
       <div className="Section">
       <SlideBar/>
       <Report/>
       </div>
       <Footer/>

      </div>
      </Route>
      <Route exact path="/EP_Marrakech/Dashboard/Consommation/:zone">
      <div className='App'>
       <Header/>
       <div className="Section">
       <SlideBar/>
       <Consommation/>
       </div>
       <Footer/>

      </div>
      </Route>
      </Switch>
      </Router>
    )
  }
}

export default App;
